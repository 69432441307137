<template>
  <v-dialog
    v-model="localDialogVisible"
    persistent
    max-width="850px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="openModal"
      >
        {{ $t("add_new") }}
      </v-btn>
    </template>
    <group-form
      v-if="localDialogVisible"
      type="Create"
      :options="options"
      @change="change"
      @onclose="closeForm"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    GroupForm: () => import("../utils/GroupForm.vue"),
  },
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      localDialogVisible: this.isVisibleDialog, // Local state for the dialog
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
  },
  watch: {
    isVisibleDialog(val) {
      this.localDialogVisible = val; // Sync local state with parent prop
    },
    localDialogVisible(val) {
      this.$emit("update:isVisibleDialog", val); // Notify parent about changes
    },
  },

  methods: {
    openModal() {
      this.localDialogVisible = true;
    },
    closeForm() {
      this.localDialogVisible = false;
    },
    change() {
      this.localDialogVisible = false;
      this.$emit("change");
    },
  },
};
</script>